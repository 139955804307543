import React from "react";

const Dropdown = ({ expanded, children }) => {
  return (
    <div
      className={`w-full max-h-2/3 flex-col divide-y-2 divide-gray-400 bg-gray-400 overflow-y-auto lg:hidden ${
        expanded ? "flex" : "hidden"
      }
      `}
    >
      {children}
    </div>
  );
};

export default Dropdown;
