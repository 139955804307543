import React from "react";
import { Link } from "gatsby";

const NavItem = (props) => {
  return (
    <Link
      className="group h-full inline-block align-middle relative border-b-4 border-transparent hover:border-sunstate-primary"
      to={props.mainItem.path}
    >
      <div className="flex w-full h-full items-center justify-center">
        <p
          to={props.mainItem.path}
          className="uppercase m-auto text-gray-700 font-semibold inline-flex items-center px-2 whitespace-no-wrap"
        >
          {props.mainItem.text}
        </p>
      </div>
      {props.dropdownItems === undefined ? null : (
        <ul className="dropdown-menu divide-y bg-sunstate-primary divide-gray-400 absolute hidden group-hover:block pt-1">
          {props.dropdownItems.map((item, index) => (
            <li key={index}>
              <Link
                to={item.path}
                className="uppercase text-gray-400 hover:text-white bg-sunstate-primary py-1 px-4 block whitespace-no-wrap"
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </Link>
  );
};

export default NavItem;
