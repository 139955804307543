import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-sunstate-darkBlue absolute bottom-0 h-70 overflow-hidden">
      <div className="w-1/2 flex flex-col justify-center items-center py-16">
        <div className="w-full flex flex-row flex-wrap justify-center items-center mb-4 md:mb-10">
          <p className="text-xs sm:text-sm md:text-md text-center text-white px-4">
            © Copyright 2020 Sunstate Medical Associates
          </p>
          <Link to="/privacy">
            <p className="text-xs sm:text-sm md:text-md text-center text-white hover:underline px-4">
              Privacy Policy
            </p>
          </Link>
          <Link to="/terms">
            <p className="text-xs sm:text-sm md:text-md text-center text-white hover:underline px-4">
              Terms & Conditions
            </p>
          </Link>
          <Link to="/contact">
            <p className="text-xs sm:text-sm md:text-md text-center text-white hover:underline px-4">
              Contact Us
            </p>
          </Link>
        </div>
        <p className="text-gray-600 text-xs sm:text-sm md:text-md text-center mb-2">
          Sunstate Medical Associates, Lake Mary, FL
        </p>
        <p className="text-gray-600 text-xs sm:text-sm md:text-md text-center mb-2">
          Phone (appointments): 407-318-2843 | Phone (general inquiries):
          407-333-3303
        </p>
        <p className="text-gray-600 text-xs sm:text-sm md:text-md text-center mb-2">
          Address: 758 North Sun Drive, Suite 104, Lake Mary, FL 32746
        </p>
      </div>
    </div>
  );
};

export default Footer;
