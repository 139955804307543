import React from "react";
import NavItem from "./NavItem";

const NavBar = () => {
  return (
    <div className="w-full h-16 flex flex-row items-center justify-center bg-white hidden lg:flex">
      <div className="w-3/5 h-full flex flex-row items-center justify-around">
        <NavItem mainItem={{ path: "/", text: "home" }} />
        <NavItem
          mainItem={{ path: "/about", text: "about us" }}
          dropdownItems={[{ path: "/provider", text: "our providers" }]}
        />
        <NavItem
          mainItem={{ path: "/contents/specialties", text: "specialties" }}
        />
        <NavItem
          mainItem={{ path: "/contents/why-sunstate", text: "why sunstate" }}
        />
        <NavItem
          mainItem={{ path: "/services", text: "services" }}
          dropdownItems={[
            { path: "/services/primary-care", text: "primary care" },
            { path: "/services/preventative-care", text: "preventative care" },
            { path: "/services/diabetes", text: "diabetes" },
            { path: "/services/weight-loss", text: "weight loss" },
            { path: "/services/arthritis", text: "arthritis" },
            { path: "/services/heart-disease", text: "heart disease" },
            { path: "/services/hypertension", text: "hypertension" },
            {
              path: "/services/depression-anxiety",
              text: "depression & anxiety",
            },
            { path: "/services", text: "view more services" },
          ]}
        />
        <NavItem
          mainItem={{
            path: "/contents/patient-testimonials",
            text: "testimonials",
          }}
        />
        <NavItem
          mainItem={{ path: "/contents/resources", text: "resources" }}
          dropdownItems={[
            {
              path: "/contents/resources/patient-forms",
              text: "patient forms",
            },
            {
              path: "/contents/resources/insurance-accepted",
              text: "insurance accepted",
            },
          ]}
        />
        <NavItem mainItem={{ path: "/blog", text: "blog" }} />
        <NavItem mainItem={{ path: "/contact", text: "contact" }} />
      </div>
    </div>
  );
};

export default NavBar;
