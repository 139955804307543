import React, { useState } from 'react';
import { Link } from 'gatsby';
import { openPopupWidget } from 'react-calendly';
import NavBar from './NavBar';
import Dropdown from './Dropdown';
import MobileNavItem from './MobileNavItem';

import sunstate from '../../img/sunstate.png';

const NavContainer = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="w-full flex flex-col fixed top-0 bg-sunstate-primary z-40">
      <div className="w-full h-16 flex flex-row divide-x lg:hidden border-b border-white">
        <div className="w-1/2 flex items-center justify-center">
          <p className="text-lg text-white py-2 text-center">407-318-2843</p>
        </div>
        <button
          onClick={() =>
            openPopupWidget({ url: 'https://calendly.com/sunstate' })
          }
          className="bg-sunstate-secondary rounded text-lg text-white px-4 py-2 ml-2 w-1/2"
        >
          Request Appointment
        </button>
      </div>
      <div className="w-full h-24 flex flex-row items-center justify-center">
        <div className="w-full h-full flex flex-row items-center justify-between px-2 lg:justify-around lg:px-12">
          <Link
            to="/"
            className="w-3/4 lg:w-2/5 max-w-xl py-4 flex items-center justify-center"
          >
            <img className="max-h-full" src={sunstate} alt="logo" />
          </Link>
          <button
            onClick={() => setExpanded(!expanded)}
            className="w-1/4 flex items-center justify-end text-white lg:hidden"
          >
            <svg
              className="fill-current h-6 w-6"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
          <div className="w-1/4 lg:w-2/5 flex-row hidden lg:flex items-center justify-center">
            <button className="border rounded text-lg text-white px-4 py-2 mr-2">
              407-318-2843
            </button>
            <button
              onClick={() =>
                openPopupWidget({
                  url: 'https://calendly.com/sunstate'
                })
              }
              className="bg-sunstate-secondary rounded text-lg text-white px-4 py-2 ml-2"
            >
              Request Appointment
            </button>
          </div>
        </div>
      </div>
      <Dropdown expanded={expanded}>
        <MobileNavItem mainItem={{ path: '/', text: 'home' }} />
        <MobileNavItem
          mainItem={{ path: '/about', text: 'about us' }}
          dropdownItems={[{ path: '/provider', text: 'our providers' }]}
        />
        <MobileNavItem
          mainItem={{ path: '/contents/specialties', text: 'specialties' }}
        />
        <MobileNavItem
          mainItem={{ path: '/contents/why-sunstate', text: 'why sunstate' }}
        />
        <MobileNavItem
          mainItem={{ path: '/services', text: 'services' }}
          dropdownItems={[
            { path: '/services/primary-care', text: 'primary care' },
            { path: '/services/preventative-care', text: 'preventative care' },
            { path: '/services/diabetes', text: 'diabetes' },
            { path: '/services/weight-loss', text: 'weight loss' },
            { path: '/services/arthritis', text: 'arthritis' },
            { path: '/services/heart-disease', text: 'heart disease' },
            { path: '/services/hypertension', text: 'hypertension' },
            {
              path: '/services/depression-anxiety',
              text: 'depression & anxiety'
            },
            { path: '/services', text: 'view more services' }
          ]}
        />
        <MobileNavItem
          mainItem={{
            path: '/contents/patient-testimonials',
            text: 'testimonials'
          }}
        />
        <MobileNavItem
          mainItem={{ path: '/contents/resources', text: 'resources' }}
          dropdownItems={[
            {
              path: '/contents/resources/patient-forms',
              text: 'patient forms'
            },
            {
              path: '/contents/resources/insurance-accepted',
              text: 'insurance accepted'
            }
          ]}
        />
        <MobileNavItem mainItem={{ path: '/blog', text: 'blog' }} />
        <MobileNavItem mainItem={{ path: '/contact', text: 'contact' }} />
      </Dropdown>
      <NavBar />
    </div>
  );
};

export default NavContainer;
