import React, { useState } from "react";
import { Link } from "gatsby";
import Dropdown from "./Dropdown";

const MobileNavItem = (props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="w-full flex flex-col justify-center flex-shrink-0">
      <div
        className={`w-full flex px-4 justify-between py-4 ${
          props.colorOffset ? "bg-sunstate-primary" : "bg-white"
        }`}
      >
        <Link
          className={`uppercase font-semibold ${
            props.colorOffset ? "text-white" : "text-gray-700"
          }`}
          to={props.mainItem.path}
        >
          {props.mainItem.text}
        </Link>
        {props.dropdownItems === undefined ? null : (
          <>
            <button
              onClick={() => setExpanded(!expanded)}
              className="text-gray-700"
            >
              <svg
                className="fill-current h-4 w-4"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {expanded ? (
                  <>
                    <title>Less</title>
                    <path d="M0 10h24v4h-24z" />
                  </>
                ) : (
                  <>
                    <title>More</title>
                    <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                  </>
                )}
              </svg>
            </button>
          </>
        )}
      </div>
      {props.dropdownItems === undefined ? null : (
        <Dropdown expanded={expanded}>
          {props.dropdownItems.map((item, index) => (
            <MobileNavItem key={index} colorOffset mainItem={item} />
          ))}
        </Dropdown>
      )}
    </div>
  );
};

MobileNavItem.defaultProps = {
  colorOffset: false,
};

export default MobileNavItem;
